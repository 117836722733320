.support {
  .typeLink {
    background: #FBF9FE;
    width: 150px;
    height:150px;
    border-radius: 10px;

    .icon {
      color: #5351FB;
      font-size: 40px;
      margin-top: 50px;
    }

    .linkTitle {
      font: normal normal normal 10px/27px Poppins;
      letter-spacing: 0.38px;
      color: #1E2022;
    }
  }

  .supportTitle {
    font: normal normal normal 18px/48px Poppins;
    letter-spacing: 0.68px;
    color: #1E2022;
  }
}