.billing {
    .tabNavigation {
        box-shadow: 0 3px 6px #00000029;
        border: 1px solid #E9EBF0;
        border-radius: 10px;

        .header {
            background: #E8EBFF 0 0 no-repeat padding-box;
            border-radius: 20px 20px 10px 10px;

            .buttonActive {
                background: #6149CD 0 0 no-repeat padding-box;
                border-radius: 25px;
                text-align: center;
                font: normal normal normal 14px/60px Poppins;
                letter-spacing: 0;
                color: #FFFFFF;
                padding: 10px;
                cursor: pointer;
            }

            .buttonNotActive {
                font: normal normal normal 14px/60px Poppins;
                letter-spacing: 0;
                color: #7A86A1;
                text-align: center;
                cursor: pointer;
                padding: 10px;
            }

            .buttonNotActive:hover {
                background: #6149CD 0 0 no-repeat padding-box;
                border-radius: 25px;
                color: #FFFFFF;
            }
        }

        .newPaymentMethodButton {
            background: #FFFFFF 0 0 no-repeat padding-box;
            box-shadow: 8px 10px 56px #0F0D230F;
            border: 1px solid #EEEFF3;
            border-radius: 10px;
            width: 220px;
            cursor: pointer;

            .addLink {
                width: 40px;
                height: 40px;
                border: 1px solid #56B3F8;
                border-radius: 10px;
                background-color: #ffffff;
                cursor: pointer;
            }

            .addLinkTitle {
                letter-spacing: 0;
                color: #020614;
                font-weight: bold;
            }
        }

        .newPaymentMethodButton:hover {
            box-shadow: 2px 4px 6px lightgrey;
        }
    }

    .list tr {
        border-bottom: solid 1px #F1F1F5;
    }

    .list td {
        padding-top:10px;
        padding-bottom:10px;
    }

    .list th {
        font: normal normal normal 14px/38px Poppins;
        letter-spacing: -0.35px;
        color: #000000;
    }

    .appIcon {
        width: 50px;
        border-radius: 25px;
        margin-right: 20px;
    }

    .appName {
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0;
        color: #08080A;
    }

    .statusActive {
        background: #b0c797;
        border-radius: 12px;
        color: #FFFFFF;
        padding: 5px 15px 5px 15px;
    }
}