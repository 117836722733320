.appsidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #CCCCCC;

  .logo {
    font-size: 35px;
  }

  .icons {
    padding-top: 50px;
  }

  .iconBox {
    width: 83px;
    padding-left: 10px;
    padding-right: 10px;
    padding: 17px;
    margin-left: 5px;
    margin-top: 17px;
    color: #868686;
    align-content: center;
  }

  .activeIconBox {
    background-color: #5351FB;
    color: white !important;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0 3px 6px #00000029;
  }

  .iconBox:hover {
    background-color: #5351FB;
    color: white !important;
    cursor: pointer;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0 3px 6px #00000029;
  }

  .icon {
    font-size: 35px;
  }

  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }

  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }

  .sidebar .nav-link.active {
    color: #2470dc;
  }

  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }

  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
}
