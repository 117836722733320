.notifications {
  .messagesList{
    background: #FFFFFF;
    box-shadow: 2px 13px 54px #695F9714;
    padding: 10px;

    .header {
      background: #FBF9FE;
      border-radius: 5px;
      padding: 20px;
      padding-left: 40px;

      .title {
        font-size: 18px;
        letter-spacing: 0;
        color: #373737;
        text-transform: uppercase;
      }
    }

    .statusScheduled {
      background: #FFF8EB;
      border-radius: 12px;
      color: #F9B035;
      padding: 5px;
    }
  }
}

.newMessageModal {
  width: 700px;

  .buttonSend {
    background: #5351FB;
    border-radius: 25px;
    width: 141px;
    height: 37px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    padding-top: 7px;
    cursor: pointer;
    opacity: 1;
  }

  .buttonSend:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px #00000021;
  }

  .scheduleSelect {
    width: 250px;
    display: block;
    margin-top: 10px;
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
    padding: 5px;

    .react-datetime-picker__wrapper {
      border: none !important;
    }
  }
}
