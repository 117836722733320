.mainPage {
  .hero {
    padding: 50px;
    background: #FBF9FE;
    border-radius: 10px;

    .title {
      font-size: 24px;
      color: #303030;
      display: block;
      margin-bottom: 20px;
    }

    .subTitle {
      font-size: 14px;
      color: #707070;
    }
  }
}
