.shop {
  .price {
    font: normal normal medium 22px Rubik;
    color: #27273D;
  }

  .templateStatus {
    font: normal normal medium 22px Rubik;
    color: #A09F9F;
  }

  .heroBlock {
    background-color: #9AACD6;
    border-radius: 20px;

    .title {
      color: white;
      font-size: 32px;
      font: normal normal bold 32px/48px Montserrat;
    }

    .subTitle {
      color: white;
      font-size: 14px;
      font: normal normal bold 14px/28px Montserrat;
    }

    .button {
      background: #FFFFFF;
      border-radius: 35px;
      font: normal normal bold 16px/28px Montserrat;
      color: #9AACD6;
    }
  }

  .mostDownload {
    box-shadow: 0 13px 72px #173A6E03;
    border: 1px solid #E9EBF0;
    border-radius: 20px;

    .title {
      font: normal normal bold 19px/31px Poppins;
      color: #08080A;
    }

    .itemTitle {
      font: normal normal bold 17px Montserrat;
      color: #08080A;
    }

    .itemSubtitle {
      font: normal normal medium 12px Montserrat;
      color: #828282;
    }

    .badgeImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: black;
      color: white;
      border-radius:10px;
    }
  }

  .title {
    font: normal normal 600 20px/30px Poppins;
    color: #27273D;
  }

  .template {
    border: 1px solid #E9EBF0;
    border-radius: 20px;
    padding: 10px;

    .image {
      border-radius: 20px;
      transition: .5s ease;
    }

    .title {
      font: normal normal medium 20px/24px Rubik;
      color: #27273D;
    }

    .subTitle {
      font: normal normal medium 14px/17px Rubik;
      color: #A09F9F;
    }

    .containerImage {
      position: relative;
    }

    .containerImage:hover .image {
      opacity: 0.3;
    }

    .containerImage:hover .middle {
      opacity: 1;
    }

    .middle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
  }
}

.marketplaceItem {
  .templateImage {
    border-radius: 8px;
  }

  .descriptionBlock {
    box-shadow: 0 13px 72px #173A6E03;
    border: 1px solid #E9EBF0;
    border-radius: 10px;

    .title {
      font: normal normal normal 46px Poppins;
      color: #1C1C1C;
    }

    .description {
      font: normal normal normal 28px Poppins;
      color: #676767;
    }

    .typeTitle {
      font: normal normal 600 16px Poppins;
      color: #1C1C1C;
    }

    .feature {
      box-shadow: 0 7px 13px #D5D5D580;
      border: 1px solid #E9EBF0;
      border-radius: 10px;
      width: 200px;

      .featureOn {
        width: 24px;
        height: 24px;
        background-color: #B0C797;
        border-radius: 10px;
      }

      .featureChecked {
        color: #2ECC72;
        font-size: 20px;
      }
    }
  }
}
