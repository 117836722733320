.sectionEditor {
  height: 671px !important;
  .list {
    width: 100%;
    padding: 5px 15px 5px 15px;
    overflow-y: scroll;
    height: 580px !important;

    .sizeSelector {
      width: 100%;
      border: 1px solid #EEEFF3;
      border-radius: 10px;


      .item {
        background: #EEEFF3;
        border-radius: 5px;
        height: 64px;
        cursor: pointer;

        .blockSize {
          background: #C4C5FB;
          height: 48px;
        }
      }

      .itemSelected {
        background: #5351FB;
      }
    }

    .iconButton {
      color: #5351FB;
      font-size: 20px;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    }

    .notActive {
      color: gray;
      cursor: not-allowed;
    }
  }
}
