@import url("https://fonts.googleapis.com/css2?family=Assistant&family=Bebas+Neue&family=Caveat&family=Cinzel:wght@400;700&family=Dancing+Script:wght@600&family=Indie+Flower&family=Josefin+Sans:wght@100;300&family=Lato&family=Lobster&family=Lora&family=Merriweather&family=Montserrat&family=Nunito&family=Open+Sans:wght@300;500;600&family=Oswald:wght@200;300;400;700&family=Poppins&family=Raleway&family=Redressed&family=Roboto:wght@100;400;900&family=Rubik&family=Shizuru&family=Ubuntu&display=swap");
@import "react-quill/dist/quill.bubble";
@import "react-quill/dist/quill.snow";
@import "ql";
@import "~bootstrap/scss/bootstrap";

:root {
  --bs-body-font-size: 13px;
}

html {
  overflow-x: hidden;
}

body {
  height: 100%;
  overflow: auto;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

.appBuilder {
  min-width: 760px !important;
}

.fileContainer .uploadPictureContainer {
  width: 90% !important;
}

.stickyHeader {
  position: sticky !important;
  top: 0 !important;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #eaeaea;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.cursorPointer {
  cursor: pointer;
}

.modal-window {
  position: fixed;
  z-index: 1000000000 !important;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow-y: auto;
  border: 2px solid #E9EBF0;
  border-radius: 10px;
  outline: none;
  padding: 20px;
  padding-top: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  .modalTitle {
    background-color: #C4C5FB52;
    border-radius: 10px;
    padding: 10px;

    .text {
      font-size: 18px;
      text-transform: uppercase;
      color: #3444CE;
    }

    .icon {
      color: #3444CE;
      font-size: 26px;
    }
  }

  .button {
    background: #5351FB;
    border-radius: 25px;
    width: 191px;
    height: 37px;
    text-align: center;
    font: normal normal normal 14px/20px;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    opacity: 1;
  }

  .button:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px #00000021;
  }
}

.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #C4C5FB52;
  width: 20px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.mainButton {
  background: #5351FB !important;
  color: #FFFFFF !important;
  cursor: pointer;
  opacity: 1;
  border-radius: 7px;
  padding: 5px;
}

.mainButtonOutline {
  border: solid 1px #5351FB !important;
  color: #5351FB !important;
  cursor: pointer;
  opacity: 1;
  border-radius: 7px;
  padding: 5px;
}

.mainButton:hover {
  opacity: 0.9;
  box-shadow: 0 5px 20px #00000021;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

p {
  padding: 0;
  margin: 0 !important;
}

.textBlock {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.42;

  img {
    max-width: 100%;
    height: auto;
  }
}

.fileContainer {
  box-shadow: none !important;
}

.simpleButton {
  border: 1px solid #5351FB;
  border-radius: 25px;
  opacity: 1;
  font: normal normal normal 14px/60px Poppins;
  letter-spacing: 0;
  color: #5351FB;
  cursor: pointer;
}

.button {
  background: #8367F9 0 0 no-repeat padding-box;
  box-shadow: 0 3px 13px #DDDFFF;
  border-radius: 25px;
  color: white;
  cursor: pointer;
}

.snowEditor {
  .ql-editor {
    min-height: 200px;
  }
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.headerButton {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 24px;
}

.headerButton:hover {
  opacity: 0.9;
  box-shadow: 0 5px 20px #00000021;
}

.buttonBGYellow {
  background: #FDD78C;
  color:white;
}

.buttonBGGray {
  background: #EEEFF3;
  color:#707070;
}

.wrapperInputIcon {
  position:relative;

  .icon {
    padding: 4px;
    font-size: 20px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    right:2px;
    transform: translateY(-50%);
  }

  .input {
    height: 50px;
    box-sizing:border-box;
    padding-right: 1.5rem;
  }
}

.primaryButton {
  border: 1px solid #5351FB;
  border-radius: 25px;
  padding: 10px;
  font: normal normal normal 12px/17px Poppins;
  letter-spacing: 0.24px;
  color: #5351FB;
  cursor:pointer;
}

.payNow {
  border: 4px solid #5351FB;
  border-radius: 25px;
  padding: 10px;
  font-size: 20px;
  letter-spacing: 0.24px;
  color: #5351FB;
  cursor:pointer;
}

.secondaryButton {
  border: 1px solid #6c757d;
  border-radius: 25px;
  padding: 10px;
  font: normal normal normal 12px/17px Poppins;
  letter-spacing: 0.24px;
  color: #6c757d;
  cursor:pointer;
}

.newPaymentMethodButton {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 8px 10px 56px #0F0D230F;
  border: 1px solid #EEEFF3;
  border-radius: 10px;
  width: 220px;
  cursor: pointer;

  .addLink {
    width: 40px;
    height: 40px;
    border: 1px solid #56B3F8;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .addLinkTitle {
    letter-spacing: 0;
    color: #020614;
    font-weight: bold;
  }
}

.newPaymentMethodButton:hover {
  box-shadow: 2px 4px 6px lightgrey;
}

.boxShadow {
  box-shadow: 0 5px 20px #00000029;
  border-radius: 24px;
  padding: 20px;
}

.ResizeHandleOuter {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width:3px;
  outline: none;
  background-color: transparent;
}
.ResizeHandleOuter[data-resize-handle-active],
.ResizeHandleInner[data-collapsed] {
  background-color: gray;
}

@media (max-width: 500px) {
  .ResizeHandleOuter {
    flex: 0 0 2.5rem;
  }
}

.ResizeHandleOuter[data-panel-group-direction="horizontal"] .HorizontalIcon,
.ResizeHandleOuter[data-panel-group-direction="vertical"] .VerticalIcon {
  display: block;
}
.ResizeHandleOuter[data-panel-group-direction="vertical"] .HorizontalIcon,
.ResizeHandleOuter[data-panel-group-direction="horizontal"] .VerticalIcon {
  display: none;
}
.ResizeHandleOuter[data-resize-handle-active] .HorizontalIcon,
.ResizeHandleOuter[data-resize-handle-active] .VerticalIcon {
  display: none;
}

.react-confirm-alert-body > h1 {
  font-size: 20px;
}

.z-index-1 {
  z-index: 1;
}

.rightClickDisabled {
  pointer-events: none;
  z-index: 0;
}

.heroCenter {
  margin: 0 auto;
}

.rssDescription {
  img {
    height: auto;
    max-width: 100%;
  }
}

.searchInput {
  width: 90%;
  max-width: 400px;
  font-size: 16px;
  padding: 5px;
  padding-left:40px;
  border: 1px solid #E9EBF0;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.manageMenuButtons {
  .buttonContainer {
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0 2px 48px #0000000A;
    border: 1px solid #EAEBEC;
    border-radius: 8px;
    font-size: 18px;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.form-check-input {
  width: 1.4em;
  height: 1.4em;
}

.form-check-input[type=checkbox] {
  border-radius: 6px;
}

.form-check-input:checked {
  background-color: rgb(86, 179, 248);
  border-color: rgb(86, 179, 248);
}
