.sharing {

  .sharingContainer {
    width: 634px;
    height: 671px;

    .form {
      margin: 15px;
      border: 1px solid #EAEBEC;
      border-radius: 10px;
      width: 605px;
      height: 560px;

      .formRow {
        font-size: 16px;
        letter-spacing: 0.86px;
        color: #3F4046;
        padding: 10px;
        border-bottom: 1px solid #EAEBEC;
      }

      .noBottomBorder {
        border-bottom: none !important;
      }
    }
  }
}
