.cardForm {
    border: 1px solid #EEEFF3;
    border-radius: 20px;
    padding: 30px;

    .title {
        font: normal normal bold 26px/39px Poppins;
        letter-spacing: 0;
        color: #144167;
    }

    .subTitle {
        font: normal normal normal 18px/27px Poppins;
        letter-spacing: 0;
        color: #A5A5A5;
    }

    label {
        font: normal normal medium 18px/27px Poppins;
        letter-spacing: 0;
        color: #536777;
    }

    .checkboxTerms {
        margin-right: 10px;
        padding-top: 5px;
    }

    .addButton {
        background: #8367F9 0 0 no-repeat padding-box;
        box-shadow: 0 3px 13px #DDDFFF;
        border-radius: 25px;
        color: white;
        cursor: pointer;
    }
}