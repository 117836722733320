.appSettings {
  .buttonSave {
    background: #6149CD;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 25px;
    color: white;
  }

  .buttonCancel {
    background: #FFFFFF;
    border: 1px solid #5351FB;
    border-radius: 25px;
    color: #5351FB;
  }
}
