.blockSource {
  height: 94px;
  background: #FFFFFF;
  box-shadow: 0 2px 48px #0000000A;
  border: 1px solid #EAEBEC;
  border-radius: 10px;
  padding: 10px;

  .name {
    font-size: 16px;
    letter-spacing: 0.14px;
    color: gray;
  }

}

.componentWithManageButtons {
  .componentBlock {
    margin: 1px;
  }
  .manageButtons {
    display: none;
    height: 20px;
    z-index: 100;
    position: absolute;

    .buttonContainer {
      width: 28px;
      height: 28px;
      background: #FFFFFF;
      box-shadow: 0 2px 48px #0000000A;
      border: 1px solid #EAEBEC;
      border-radius: 8px;
      font-size: 18px;
    }
  }

}

.componentWithManageButtons:hover {
  .manageButtons {
    display: block;
    cursor: pointer !important;
  }
  .componentBlock {
    margin: 0;
    border: dashed 1px gray;
  }

}

.inEditBlock {
  margin: 0 !important;
  outline: dashed 1px black;
}

.inSelectedBlock {
  outline: 1px solid black;
}
