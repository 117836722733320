.appPage {
  .manageBlock {
    background-color: #ffffff;
    box-shadow: 0 5px 20px #00000021;

    .titleBlock {
      width: 100%;
      height: 78px;
      padding: 5px 10px 5px 10px;
      border-bottom: solid 1px #e2e2e2;

      .title {
        margin: 5px;
        height: 58px;
        background: #5351FB;
        border-radius: 10px;

        .name {
          font-size: 16px;
          text-transform: uppercase;
          color: #ffffff;
          padding-top: 20px;
        }

        .icon {
          color: #ffffff;
          font-size: 30px;
        }

        .iconBlock {
          padding: 15px;
          padding-left: 25px;
        }
      }
    }

    .list {
      height: 516px;
    }

    .bottom {
      width: 100%;
      height: 78px;
      padding: 5px 10px 5px 10px;
      border-top: solid 1px #e2e2e2;

      .title {
        margin: 5px;
        height: 58px;
        background: #D8D8D82B;
        border-radius: 10px;

        .name {
          font-size: 14px;
          color: #383733;
          padding-top: 17px;
        }

        .icon {
          color: #89CAFB;
          font-size: 30px;
        }

        .iconBlock {
          padding: 15px;
          padding-left: 25px;
        }
      }
    }
  }

  .sectionEditBg {
    background-color: #C4C5FB52 !important;
  }

  .sectionEditText {
    color: #3444CE !important;
  }
}
