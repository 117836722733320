.ecommerce {
  .hero {
    padding: 20px !important;

    .title {
      margin-bottom: 0 !important;
    }
  }

  .tagItem {
    border: 1px solid #5351FB;
    border-radius: 10px;
    padding: 5px;
    font: normal normal normal 12px/17px Poppins;
    letter-spacing: 0.24px;
    color: #5351FB;
    cursor:pointer;
    display: inline;
  }

  .tabNavigation {
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #E9EBF0;
    border-radius: 10px;

    .header {
      background: #E8EBFF 0 0 no-repeat padding-box;
      border-radius: 20px 20px 10px 10px;

      .buttonActive {
        background: #5351fb 0 0 no-repeat padding-box;
        border-radius: 25px;
        text-align: center;
        font: normal normal normal 14px/60px Poppins;
        letter-spacing: 0;
        color: #FFFFFF;
        padding: 10px;
        cursor: pointer;
      }

      .buttonNotActive {
        font: normal normal normal 14px/60px Poppins;
        letter-spacing: 0;
        color: #7A86A1;
        text-align: center;
        cursor: pointer;
        padding: 10px;
      }

      .buttonNotActive:hover {
        background: #5351fb 0 0 no-repeat padding-box;
        border-radius: 25px;
        color: #FFFFFF;
      }
    }
  }

  .newProductButton {
    background: #5351FB;
    border-radius: 10px;
    color: white;
    padding: 10px;
  }

  .category {
    min-height: 200px;
    .area {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #E8EBFF;
      border-radius: 20px;

      .header {
        background: #FBF9FE no-repeat padding-box;
        border-radius: 20px 20px 10px 10px;
        padding: 20px;
        font: normal normal normal 14px/20px Poppins;
        color: #858997;
      }

      .type {
        border-top: 1px solid #E8EBFF;
        font: normal normal normal 14px/21px Roboto;
        color: #868686;
        padding: 15px;
        padding-left: 25px;
        cursor: pointer;
      }

      .type:hover {
        padding-left: 15px;
      }

      .type:hover span {
        background: #E8EBFF 0% 0% no-repeat padding-box;
        border-radius: 50px;
        padding: 10px;
        color: #7A86A1;
      }

      .active {
        background: #E8EBFF 0% 0% no-repeat padding-box;
        border-radius: 50px;
        padding: 10px;
        color: #7A86A1;
      }

      .activeType {
        padding-left: 15px;
      }

      .categoryType {
        border-top: 1px solid #E8EBFF;
        font: normal normal normal 14px/21px Roboto;
        color: #868686;
        padding: 15px;
        padding-left: 25px;
        cursor: pointer;
      }

      .categoryType span:before {
        content:"• ";
      }
    }
  }

  .product {
    .typeTitle {
      color: #868686;
    }

    .descriptionBlock {
      background: #FFFFFF 0 0 no-repeat padding-box;
      border: 1px solid #EEEFF3;
    }

    .newVariantButton {
      border: 1px solid #5351FB;
      border-radius: 25px;
      padding: 10px;
      font: normal normal normal 12px/17px Poppins;
      letter-spacing: 0.24px;
      color: #5351FB;
      cursor:pointer;
    }
  }

  .inlineSelect {
    border: 0 !important;
  }

  .inlineSelect:focus {
    outline: none;
  }

  .selectLabel {
    color: #92929D;
  }

  .inlineSearch {
    border: 1px solid #E2E2EA;
    border-radius: 10px;
  }

  .products th {
    color: #5351fb;
    text-transform: uppercase;
  }

  .products tr {
    border-bottom: solid 1px #F1F1F5;
  }

  .addButton {
    border: 1px solid #5351FB;
    border-radius: 25px;
    padding: 10px;
    text-align: center;
    font: normal normal normal 12px/17px Poppins;
    letter-spacing: 0.24px;
    color: #5351FB;
    cursor: pointer;
  }

  .saveButton {
    background: #5351FB 0 0 no-repeat padding-box;
    border-radius: 30px;
    padding: 10px 30px 10px 30px;
    text-align: center;
    cursor: pointer;
    font: normal normal normal 12px/18px Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
  }

  .cancelButton {
    padding: 10px 30px 10px 30px;
    text-align: center;
    cursor: pointer;
    font: normal normal normal 12px/18px Roboto;
    letter-spacing: 0;
    color: #858997;
  }
}