.ql-editor {
  padding: unset !important;
  font-size: inherit !important;
}
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-bubble .ql-tooltip {
  border-radius: 5px !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="30px"]::before
{
  content: "30px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="29px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="29px"]::before
{
  content: "29px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="28px"]::before
{
  content: "28px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="27px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="27px"]::before
{
  content: "27px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="26px"]::before
{
  content: "26px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="25px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="25px"]::before
{
  content: "25px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before
{
  content: "24px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="23px"]::before
{
  content: "23px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="22px"]::before
{
  content: "22px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="21px"]::before
{
  content: "21px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before
{
  content: "20px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="19px"]::before
{
  content: "19px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="18px"]::before
{
  content: "18px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="17px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="17px"]::before
{
  content: "17px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before
{
  content: "16px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="15px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="15px"]::before
{
  content: "15px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before
{
  content: "14px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="13px"]::before
{
  content: "13px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before
{
  content: "12px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="11px"]::before
{
  content: "11px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before
{
  content: "10px" !important;
}

.appPreview a {
  text-decoration: none !important;
}

.appPreview a, a:visited, a:hover, a:active {
  color: inherit;
}

.builderWorkspace a {
  text-decoration: none !important;
}

.builderWorkspace a, a:visited, a:hover, a:active {
  color: inherit;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="30px"]::before
{
  content: "30px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="29px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="29px"]::before
{
  content: "29px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="28px"]::before
{
  content: "28px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="27px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="27px"]::before
{
  content: "27px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="26px"]::before
{
  content: "26px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="25px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="25px"]::before
{
  content: "25px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before
{
  content: "24px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="23px"]::before
{
  content: "23px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="22px"]::before
{
  content: "22px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="21px"]::before
{
  content: "21px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before
{
  content: "20px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="19px"]::before
{
  content: "19px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="18px"]::before
{
  content: "18px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="17px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="17px"]::before
{
  content: "17px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before
{
  content: "16px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="15px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="15px"]::before
{
  content: "15px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before
{
  content: "14px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="13px"]::before
{
  content: "13px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before
{
  content: "12px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="11px"]::before
{
  content: "11px" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before
{
  content: "10px" !important;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Assistant"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Assistant"]::before
{
  font-family: "Assistant";
  content: "Assistant" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Caveat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Caveat"]::before
{
  font-family: "Caveat";
  content: "Caveat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
  font-family: "Lato";
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lobster"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lobster"]::before
{
  font-family: "Lobster";
  content: "Lobster" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lora"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lora"]::before
{
  font-family: "Lora";
  content: "Lora" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Merriweather"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Merriweather"]::before
{
  font-family: "Merriweather";
  content: "Merriweather" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
{
  font-family: "Montserrat";
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Nunito"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Nunito"]::before
{
  font-family: "Nunito";
  content: "Nunito" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Oswald"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Oswald"]::before
{
  font-family: "Oswald";
  content: "Oswald" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Poppins"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Poppins"]::before
{
  font-family: "Poppins";
  content: "Poppins" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
  font-family: "Raleway";
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Redressed"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Redressed"]::before
{
  font-family: "Redressed";
  content: "Redressed" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
{
  font-family: "Rubik";
  content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Shizuru"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Shizuru"]::before
{
  font-family: "Shizuru";
  content: "Shizuru" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Ubuntu"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Ubuntu"]::before
{
  font-family: "Ubuntu";
  content: "Ubuntu" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="BebasNeue"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="BebasNeue"]::before
{
  font-family: "Bebas Neue";
  content: "Bebas Neue" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Cinzel"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Cinzel"]::before
{
  font-family: "Cinzel";
  content: "Cinzel" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="DancingScript"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="DancingScript"]::before
{
  font-family: "Dancing Script";
  content: "Dancing Script" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="IndieFlower"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="IndieFlower"]::before
{
  font-family: "Indie Flower";
  content: "Indie Flower" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="JosefinSans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="JosefinSans"]::before
{
  font-family: "Josefin Sans";
  content: "Josefin Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="OpenSans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="OpenSans"]::before
{
  font-family: "Open Sans";
  content: "Open Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
  font-family: "Roboto";
  content: "Roboto" !important;
}

.ql-font-Assistant {
  font-family: "Assistant";
}
.ql-font-Caveat {
  font-family: "Caveat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Lobster {
  font-family: "Lobster";
}
.ql-font-Lora {
  font-family: "Lora";
}
.ql-font-Merriweather {
  font-family: "Merriweather";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Nunito {
  font-family: "Nunito";
}
.ql-font-Oswald {
  font-family: "Oswald";
}
.ql-font-Poppins {
  font-family: "Poppins";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Redressed {
  font-family: "Redressed";
}
.ql-font-Rubik {
  font-family: "Rubik";
}
.ql-font-Shizuru {
  font-family: "Shizuru";
}
.ql-font-Ubuntu {
  font-family: "Ubuntu";
}
.ql-font-BebasNeue {
  font-family: "Bebas Neue";
}

.ql-font-Cinzel {
  font-family: "Cinzel";
}

.ql-font-DancingScript {
  font-family: "Dancing Script";
}

.ql-font-IndieFlower {
  font-family: "Indie Flower";
}

.ql-font-JosefinSans {
  font-family: "Josefin Sans";
}

.ql-font-OpenSans {
  font-family: "Open Sans";
}

.ql-font-Roboto {
  font-family: "Roboto";
}

.ql-height-050 {
  line-height: 0.5 !important;
}

.ql-height-075 {
  line-height: 0.75 !important;
}

.ql-height-100 {
  line-height: 1 !important;
}

.ql-height-125 {
  line-height: 1.25 !important;
}

.ql-height-150 {
  line-height: 1.75 !important;
}

.ql-height-175 {
  line-height: 1.75 !important;
}

.ql-height-200 {
  line-height: 2 !important;
}

.ql-picker.ql-height .ql-picker-label[data-value="0.5"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="0.5"]::before
{
  content: "Height: 0.5";
}

.ql-picker.ql-height .ql-picker-label[data-value="0.75"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="0.75"]::before
{
  content: "Height: 0.75";
}

.ql-picker.ql-height .ql-picker-label[data-value="1"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="1"]::before
{
  content: "Height: 1";
}

.ql-picker.ql-height .ql-picker-label[data-value="1.25"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="1.25"]::before
{
  content: "Height: 1.25";
}

.ql-picker.ql-height .ql-picker-label[data-value="1.5"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="1.5"]::before
{
  content: "Height: 1.5";
}

.ql-picker.ql-height .ql-picker-label[data-value="1.75"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="1.75"]::before
{
  content: "Height: 1.75";
}

.ql-picker.ql-height .ql-picker-label[data-value="2"]::before,
.ql-picker.ql-height .ql-picker-item[data-value="2"]::before
{
  content: "Height: 2";
}

.ql-bubble .ql-picker.ql-height {
  width: 98px;
}

.ql-bubble .ql-color-picker .ql-picker-options {
  right: 0;
}

.ql-tooltip {
  z-index: 1000000 !important;
  width: 275px !important;
}

.ql-formats {
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.ql-color {
  margin-top: 3px;
  margin-left: 5px;
  background: whitesmoke !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
}

.ql-bold {
  width: 37px !important;
  padding-left: 15px !important;
}

.ql-italic {
  width: 37px !important;
  padding-left: 15px !important;
}

.ql-underline {
  width: 37px !important;
  padding-left: 15px !important;
}

.ql-link {
  width: 37px !important;
  padding-left: 15px !important;
}

.ql-editor {
  overflow: hidden;
}

.ql-picker-options {
  max-height: 150px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #77838F;
}
