.navigator {
  width: 334px;
  height: 671px;

  .list {
    overflow-y: scroll;
    height: 580px !important;

    .title {
      padding: 20px 20px 0px 40px;
      color: #3F4046;
      font-size: 17px;
      letter-spacing: 0.69px;
    }

    .sections {
      border: 1px solid #EAEBEC;
      border-radius: 10px;
      margin: 10px 10px 10px 10px;
      padding: 10px;

      .section {
        padding: 10px;

        .sectionTitle {
          font-size: 16px;
          letter-spacing: 0.71px;
          color: #3F4046;
        }

        .imageNotSelected {
          opacity: 0.5;
          filter: grayscale(1);
        }
      }

      .borderBottom {
        border-bottom: solid 1px #EAEBEC;
      }

      .borderTop {
        border-top: solid 1px #EAEBEC;
      }

      .sectionSize {
        border-radius: 7px;
        border: solid 2px #EEEFF3;

          .item {
            border: solid 3px #EEEFF3;
            height: 25px;
          }
      }

      .sectionSizeSelected {
        border-radius: 7px;
        border: solid 2px #5351FB;

        .item {
          border: solid 3px #5351FB;
          height: 25px;
        }
      }
    }
  }
}
