.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #CCCCCC;

  .logo {
    font-size: 35px;
  }

  .icons {
    padding-top: 50px;
  }

  .iconBox {
    width: 78px;
    padding: 17px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px;
    color: #868686;
    align-content: center;
  }

  .activeIconBox {
    background-color: #5351FB;
    color: white !important;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
  }

  .iconBox:hover {
    background-color: #5351FB;
    color: white !important;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
  }

  .icon {
    font-size: 35px;
  }

  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }

  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }

  .sidebar .nav-link.active {
    color: #2470dc;
  }

  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }

  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
}

.header {
  .title {
    color: #303030;
    font-size: 24px;
    font-weight: bold;
  }
  .username {
    color: #12141E;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  .avatar {
    width: 53px;
    height: 52px;
    background-color: #5351FB;
    color: white !important;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: bold;
  }

  .appTitle {
    font-size: 22px;
    color: #303030;
    font-weight: bold;
  }
}

.sidePanel {
  .username {
    color: #12141E;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  .avatar {
    width: 53px;
    height: 52px;
    background-color: #5351FB;
    color: white !important;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: bold;
  }
  .divider {
    background-color: #E2E2EA;
    height: 2px;
    margin-right: 8px;
    margin-left: 8px;
  }
  .title {
    color: #92929D;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-left: 30px;
  }
  .link {
    cursor: pointer;

    .icon {
      font-size: 25px;
    }

    .iconBox {
      padding: 5px;
    }

    .iconTitle {
      color: #868686;
      font-size: 14px;
    }
  }

  .link:hover,.active {
    background-color: #FBF9FE;
    border-radius: 5px;

    .icon {
      color: white !important;
    }

    .iconBox {
      background-color: #5351FB;
      border-radius: 5px;
      box-shadow: 0 3px 6px #00000029;
    }

    .iconTitle {
      color: #5351FB !important;
    }
  }
}


