.pages {
  width: 334px;
  height: 671px;

  .list {
    overflow-y: scroll;

    .pageBlock {

      .pageTitle {
        font-size: 16px;
        color: #BABABA;
        letter-spacing: 1.67px;
        font-weight: 600;
        overflow-x: scroll;
        white-space: initial;
      }

      .pageTitleActive {
        color: #383733;
      }
    }

    .active {
      box-shadow: 0 2px 20px #0000001A;
      border-radius: 10px;
    }
  }
}

.newPageModal {
  width: 400px;
}

.previewModal {
  width: 410px;
}

.editPage {
  .blockTitle {
    color: #3F4046;
    font-size: 11px;
    text-transform: uppercase;
  }

  .options {
    .pageOption {
      .title {
        font-size: 10px;
        letter-spacing: 0.71px;
        color: #3F4046;
      }

      .typeBlock {
        background: #EAEBEC;
        width: 34px;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        color: white;
      }

      .activeType {
        background: #B0C797;
      }
    }

    hr {
      height: 1px;
      width: 98%;
      background-color: #E6E6E6;
    }
  }

  .block {
    background: #FFFFFF;
    border: 1px solid #EAEBEC;
    border-radius: 10px;
    padding: 10px;
  }

  .blockShadow {
    box-shadow: 0 2px 48px #0000000A;
  }

  .settingName {
    color: #3F4046;
    font: normal normal normal 9px/13px Poppins;
    letter-spacing: 0.64px;
  }

  .actionButton {
    text-align: center;
    padding: 5px;
    border-radius: 12px;
    border: 1px solid #EAEBEC;

    .text {
      color: #77838F;
      font-size: 9px;
    }

    .icon {
      color: #77838F;
      font-size: 25px;
    }
  }

  .actionButton:hover {
    background-color: #A5A5A5;

    .text {
      color: #FFFFFF;
    }

    .icon {
      color: #FFFFFF;
    }
  }
;
}
