.content {
  width: 100%;
  padding: 15px;
  margin: auto;

  .auth {
    .content {

      .centerBlock {
        border: 2px solid #E9EBF0;
        border-radius: 10px;

        .logoTitle {
          font-size: 10px;
          letter-spacing: 0;
          font-weight: 900;
          color: #090E3A;
        }

        .image {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        .form {
          padding-left: 3vw;
          padding-right: 3vw;
          padding-top: 3vw;
          .title {
            font-size: 31px;
            font-weight: 600;
            letter-spacing: 0;
            color: #060C43;
          }

          .label {
            position: absolute;
            margin-top: -0.25rem !important;
            margin-right: 3rem !important;
          }

          .forgotPassword {
            font-size: 13px;
            letter-spacing: 0.18px;
            color: #000000;
          }

          .button {
            background-color: #5351fb;
            border-color: #5351fb;
          }

          .googleSignIn {
            font-size: 14px;
            letter-spacing: 0.28px;
            color: #060C43;
          }

          .orSignIn {
            font-size: 11px;
            letter-spacing: 0.16px;
            color: #626476;
          }

          .orSignUp {
            font-size: 9px;
            letter-spacing: 0.18px;
            color: #060C43;
          }
        }
      }
    }
  }
}
