.blogpost {
  .hero {
    padding: 20px !important;

    .title {
      margin-bottom: 0 !important;
    }
  }

  .posts th {
    color: #5351fb;
    text-transform: uppercase;
  }

  .tabNavigation {
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #E9EBF0;
    border-radius: 10px;

    .header {
      background: #E8EBFF 0 0 no-repeat padding-box;
      border-radius: 20px 20px 10px 10px;

      .buttonActive {
        background: #6149CD 0 0 no-repeat padding-box;
        border-radius: 25px;
        text-align: center;
        font: normal normal normal 14px/60px Poppins;
        letter-spacing: 0;
        color: #FFFFFF;
        padding: 10px;
        cursor: pointer;
      }

      .buttonNotActive {
        font: normal normal normal 14px/60px Poppins;
        letter-spacing: 0;
        color: #7A86A1;
        text-align: center;
        cursor: pointer;
        padding: 10px;
      }

      .buttonNotActive:hover {
        background: #6149CD 0 0 no-repeat padding-box;
        border-radius: 25px;
        color: #FFFFFF;
      }
    }
  }

  .categoryType {
    border-top: 1px solid #E8EBFF;
    font: normal normal normal 14px/21px Roboto;
    color: #868686;
    padding: 15px;
    padding-left: 25px;
    cursor: pointer;
  }

  .categoryType span:before {
    content:"• ";
  }

  .newPostButton {
    background: #5351FB;
    border-radius: 10px;
    color: white;
    padding: 10px;
  }
}