.newAppDashboard {
  width:193px;
  height:272px;
  position: relative;

  .addLink {
    position: absolute;
    width: 132px;
    height: 132px;
    border: 5px solid #56B3F8;
    border-radius: 19px;
    top: -66px;
    left: 33px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .addLink:hover {
    box-shadow: 2px 3px 14px #b0e2ff;
  }

  .innerBox {
    margin-top: 66px;
    height: 206px;
    background: #FFFFFF;
    box-shadow: 8px 10px 56px #0F0D230A;
    border-radius: 10px;
    border: 1px solid #eeeff3;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #020614;
      padding-top: 80px;
    }

    .button {
      margin-top: 10px;
      width: 145px;
      height: 40px;
      margin-left: 28px;
      background: #FFFFFF;
      border: 5px solid #eaf1fc;
      border-radius: 30px;
      color: #49AEF8;
      font-size: 14px;
      padding-top: 4px;
      cursor: pointer;
    }

    .button:hover {
      box-shadow: 0 3px 6px #b0e2ff;
    }
  }
}
