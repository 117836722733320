.blocks {

  .blocksContainer {
    width: 334px;
    height: 671px;

    .list {
      overflow-y: scroll;
      height: 580px !important;
    }
  }

  .appEditContainer {
    width: 393px;
    height: 671px;
    box-shadow: 0 5px 20px #00000029;
    border-radius: 24px;
    padding: 15px 10px 15px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: static;

    .addSectionButton {
      hr.line {
        border: 3px solid #5351FB;
        opacity: 1;
      }

      .addIcon {
        width: 50px;
        height: 50px;
        background-color: #3444CE;
        border-radius: 25px;
        color: white;
        font-size: 40px;
        line-height: 40px;
        padding-top: 5px;
        cursor: pointer;
        text-align: center;
      }

      .addIcon:hover {
        opacity: 0.9;
      }
    }

    .sectionRowSelected {
      border: 1px solid #5351FB;
      border-radius: 5px;
    }

    .sectionRow {
      padding: 5px;

      .blankBlock {
        border-radius: 5px;
        background: #EEEFF3;
        min-height: 128px;

        .addIcon {
          width: 50px;
          height: 50px;
          background-color: #C4C5FB;
          border-radius: 25px;
          color: white;
          padding-top: 4px;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
