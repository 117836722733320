.profilePage {
  .editArea {
    border: 1px solid #E9EBF0;
    border-radius: 20px;
  }

  .addButton {
    background: #8367F9 0 0 no-repeat padding-box;
    box-shadow: 0 3px 13px #DDDFFF;
    border-radius: 25px;
    color: white;
    cursor: pointer;
  }
}