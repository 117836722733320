.blockEdit {
  .editItem {
    box-shadow: 0 2px 48px #0000000A;
    border: 1px solid #EAEBEC;
    border-radius: 10px;
    margin-bottom: 5px;

    .onOff {
      .image {
        width: 40px;
        height: 40px;
        background: #E8EBFF 0 0 no-repeat padding-box;
        border-radius: 4px;
        margin-top: 2px;
        padding-top: 5px;

        .title {
          font-size: 12px;
          letter-spacing: 0.86px;
          color: #3444CE;
          text-transform: uppercase;
        }
      }
    }

    .blockTitle {
      font-size: 13px;
      color: #3F4046;
    }

    .subElement {
      border-top: 1px solid #EAEBEC;
      padding: 5px 10px 5px 10px
    }

    .subElementNoBorderTop {
      border-top: unset !important;
    }

    .spacing {
      .typeSelector {
        font-size: 13px;
        color: #3F4046;
        cursor: pointer;
      }

      .typeSelected {
        color: #5351FB;
      }

      .spacingTypeButton {
        border: 1px solid #EEEFF3;
        border-radius: 3px;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 7px 5px 7px;
      }

      .spacingTypeSelected {
        color: #5351FB;
      }

      .sizeInput {
        font-size: 13px;
        height: 20px;
        width: 30px;
        color: #A3A4A6;
        border: 1px solid #EEEFF3;
      }
    }
  }

  .fileContainer {
    box-shadow: unset !important;
  }

  .animation {
    .typeSelectorBlock {
      background-color: #fafafa;
      padding: 10px;

      .types {
        box-shadow: 0 2px 48px #0000000A;
        border: 1px solid #EAEBEC;
        border-radius: 5px;

        .type {
          padding: 10px 20px 10px 20px;
          cursor: pointer;

          .title {
            font-size: 13px;
            color: #3F4046;
          }

          .icon {
            font-size: 20px;
            color: #3F4046;
          }
        }

        .typeSelected {
          background-color: #C4C5FB52;
        }

        .typeBorder {
          border-bottom: 1px solid #EAEBEC;
        }
      }
    }

    .adjustmentBlock {
      background-color: #ffffff;
      padding: 10px 20px 10px 20px;

      .title {
        font-size: 13px;
        color: #3F4046;
      }

      .titleSecond {
        font-size: 13px;
        color: #5351FB;
      }

      .adjustmentType {
        font-size: 9px;
        color: #3F4046;
      }

      .typeSelector {
        font-size: 13px;
        color: #3F4046;
        cursor: pointer;
      }

      .typeSelected {
        color: #5351FB;
      }

      .spacingTypeButton {
        border: 1px solid #EEEFF3;
        border-radius: 3px;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 7px 5px 7px;
      }

      .spacingTypeSelected {
        color: #5351FB;
      }

      .sizeInput {
        font-size: 13px;
        height: 20px;
        width: 30px;
        color: #A3A4A6;
        border: 1px solid #EEEFF3;
      }
    }
  }

  .addElementIcon {
    width: 30px;
    height: 30px;
    padding-top:3px;
    background-color: #5351FB;
    border-radius: 15px;
    color: white;
    font-size: 25px;
    line-height: 25px;
    cursor: pointer;
    text-align: center;
  }

  .addElementIcon:hover {
    opacity: 0.9;
  }
}
